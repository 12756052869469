<template>
  <div class="container">
    <div class="info">是否授权登录</div>
    <el-button type="primary" @click="handleAuthorize">授权登录</el-button>
  </div>
</template>

<script>
import { oauthAuthorize } from '@/api/user';
import Qs from 'qs'
export default {
  methods: {
    handleAuthorize() {
      let q = undefined
      const sps = window.location.href.substring(window.location.href.indexOf('?') + 1).split('#')
      for (let i = 0; i < sps.length; i++) {
        const s = sps[i]
        if (s.indexOf("client_id") >= 0) {
          q = Qs.parse(s)
          break
        }
      }
      if (q) {
        oauthAuthorize(q).then(res => {
          console.log(q, res.data)
          if (q.response_type == "token") {
            const redirect_uri = q.redirect_uri + '?access_token=' + res.data.access_token + '&state=' + q.state
            window.location.href = redirect_uri
          } else {
            const redirect_uri = q.redirect_uri + '?code=' + res.data.code + '&state=' + q.state
            window.location.href = redirect_uri
          }
        }).catch(e => {
          console.log(e)
        })
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.info {
  margin-bottom: 40px;
}
</style>